/**
 * Return the optimized image URL in production mode
 *
 * @param imagePath Image path
 */
export function getOptimizedImageUrl(imagePath: string, width: number) {
  if (process.env.NODE_ENV === "production") {
    return `/_vercel/image?url=${encodeURIComponent(
      imagePath,
    )}&w=${encodeURIComponent(width)}&q=75`;
  }

  return imagePath;
}
